body {
    position: sticky;
}

.mylist_content {
    width: 100%;
    padding: 100px 24px 24px;
    /* font-family: "Jost", sans-serif; */
}

.contact_header {
    width: 100%;
    height: 100%;
}

.contact_content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    color: #fff;
    padding: 24px;
}

.contact_content ul {
    margin: 0 auto;


}

.contact_content ul li {
    padding: 10px;
    list-style-type: none;

}

.mac {
    color: #000;
    padding-right: 10px;
}

.mac-inputbox {
    width: 200px;
    height: 40px;
    font-size: 15px;
    padding: 8px 12px;
    border-radius: 4px;
}

.mylist_content p {
    font-size: 16px;
    color: #fff;
}

.mylist_content_card {
    background-color: #2d2b38;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.mylist_content_card h1 {
    font-family: sans-serif;
    color: rgba(255, 255, 0, 0.644);
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    padding-bottom: 24px;
}

.centered {
    align-items: center;
    width: 100%;
    margin: 15px;
    line-height: 30px;
}

.centered .para {
    background-color: black;
    width: 100%;
    text-align: center;
    color: white;
    margin: 10px;
}

.centered .para1 {
    background-color: black;
    margin: 10px;
    width: 100%;
    text-align: center;
    color: white;
}

.centered .para2 {
    background-color: black;
    margin: 10px;
    width: 100%;
    text-align: center;
    color: gold;
}

.mylist_form {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    padding: 24px;
    margin-top: 0px;
}

.mylist_form .left_inputbox {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.label_name,
.url {
    color: #000;
    min-width: 50px;
    text-align: left;
}

.inputform {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.inputtype {
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: center;
    align-items: center;
}

.text_input,
.url-input {
    width: 200px;
    height: 40px;
    padding: 8px 12px;
    border-radius: 4px;
}

.mylist_form input,
.deleteForm input {
    font-size: 15px;
    width: 250px;
    margin: 0;
    height: 40px;
    padding: 8px 12px;
    border-radius: 4px;
}

.mylist_form .right_inputbox {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.mylist_form .right div {
    display: flex;
    flex-direction: row;
}

.fa-solid {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    border: 2px solid black;
    border-radius: 48px;
    cursor: pointer;
    margin-top: 0px;
    color: #06a506;
}

.send {
    color: white;
    background-color: #3e8ef7;
    font-size: 25px;
    width: 200px;
    align-self: center;
    border: none;
    border-radius: 15px;
    height: 50px;
    cursor: pointer;
    margin: 16px 0;
}

.deleteForm {
    height: auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 12px;
    margin-top: 40px;
    padding: 24px;
    align-items: center;
    justify-content: center;
}

.deleteForm .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.deleteForm label {
    font-size: 15px;
    display: inline;

}

.delete_btn button {
    height: 35px;
    padding: 5px;
    width: 100px;
    background-color: red;
    color: white;
    border: none;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;

}

.input-boxes {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
}


@media only screen and (max-width:820px) {
    .contact_content {
        display: grid;
        grid-template-rows: repeat(1, 1fr);
        text-align: center;
    }

    .mylist_form,
    .deleteForm {
        flex-direction: column;
        align-items: start;
    }

    .deleteForm .left {
        flex-direction: column;
        align-items: start;
        width: 100%;
    }

    .mylist_form .left_inputbox label {
        margin-bottom: 8px;
    }

    .mylist_form .left_inputbox input {
        width: 100%;
    }

    .mylist_form .left_inputbox {
        flex-direction: column;
        align-items: start;
        width: 100%;
    }

    .text_input,
    .url-input {
        width: 100%;
    }

    .inputform {
        width: 100%;
    }

    .inputtype {
        flex-direction: column;
        width: 100%;
        align-items: start;
    }

    .mylist_form input,
    .deleteForm input {
        width: 100%
    }

    .input-boxes {
        flex-direction: column;
        width: 100%;
    }

    .deleteForm .input-left {
        margin-bottom: 10px;

    }

    .fa-solid {
        font-size: 30px;
        border: 2px solid black;
        border-radius: 90px;
        padding: 3px;
        cursor: pointer;
        color: #06a506;
    }

    .send {
        margin-bottom: 0;
    }

}