.please_note_class {
    color: red;
}

.activation_content {
    margin: auto;
    width: 100%;
    padding: 100px 24px 24px 24px;
    /* font-family: "Jost", sans-serif; */
    border-radius: 5px;
}

.activation_content_card {
    background-color: #2d2b38;
    border-radius: 5px;
    padding: 30px;
    margin-top: 10px;
    margin-bottom: 10px;

}


.activation_content .activation_content_card h1 {

    color: #fff;
    font-style: normal;
    padding-top: 40px;

}

.p_class h1 {
    font-size: 24px;

}

.p_class {
    line-height: 35px;
    font-size: medium;
    color: #fff;
}

.mac_class {
    display: flex;
    flex-direction: column;
    align-items: center;


}

.mac_class button {
    width: 88px;
    border-color: rgb(177, 131, 14);
    color: rgb(177, 131, 14);
    font-weight: bold;

}

.checkbox label {
    font-size: 18px;
}

.Check_btn button {
    height: 35px;
    padding: 5px;
    width: 100px;
    background-color: rgb(177, 131, 14);
    color: white;
    border: none;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;

}




@media screen and (max-width:768px) {
    .mac_class input {
        width: 100px;
        margin: 0;
        height: 23px;

    }

    .checkbox {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

}