.navbar {
    justify-content: space-between;
    display: flex;
    align-items: center;
    background-color: #1e1c2a;
    color: white;
    position: fixed;
    width: 100%;
    height: 68px;
    padding: 0 24px

}

.nav-links {
    display: flex;
    gap: 12px;
    list-style: none;
    align-items: center;
    justify-content: center;

}

.navbar li:hover {
    color: rgb(220, 21, 124);

}

.navbar li{
    color: gold;
}

.menu_text {
    font-size: 18px;
    color: white;
    cursor: pointer;
    text-align: center;
    list-style: none;


}
.manageplaylist{
    font-size: 18px;
    color: white;
    cursor: pointer;
    text-align: center;
    list-style: none;
    font-weight: bolder;
    
    
    
}

.logo {
    max-height: 70px;
    width: 100px;
    height: 70px;

}.home{
    text-decoration: none;
}

.logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-left: 20px;
}

.mobile-menu-icon {
    display: none;
}


.manage_button {
    background-color: rgb(177, 131, 14);
    border-radius: 12px;
    border: 1px solid #000000;
    padding: 6px;
    max-width: 200px;
    margin: auto;
}
.nav-links-mobile{
display: none;
}

@media screen and (max-width:880px) {
    .nav-links {
       gap: 8px;
    
    }  
}



@media screen and (max-width:780px) {
    .nav-links {
        display: none;
    }

    .nav-links-mobile {
        position: absolute;
        display: block;
        list-style: none;
        background-color: #1e1c2a;
        left: 0;
        top: 75px;
        transition: all 0.5s ease-out;
        width: 100%;
        height: 100vh;

    }

    .menu_text {
        color: whitesmoke;
        text-align: center;
        padding: 32px;
        width: 100%;
        transition: all 0.5s ease;
    }

    .mobile-menu-icon {
        display: block;
        font-size: 30px;
        color: whitesmoke;
        background-color: #1e1c2a;
        border: none;
        outline: none;
        width: 32px;
        height: 32px;
        margin-right: 12px;
    }



}

.icon {
    width: 100%;
    height: 100%;
}
