.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 100px 24px 24px 24px;

}

.support_container {
    background-color: #2d2b38;

    border-radius: 5px;
    padding: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
}

.support_container h1 {
    font-size: 32px;
    font-family: sans-serif;
    color: rgba(255, 255, 0, 0.644);
    text-align: center;
    justify-content: center;
    margin: 20px;
}

.contact_header {
    width: 100%;
    height: 100%;
}

.support_container h3 {
    color: #fff;
}

.support_container p {
    color: #fff;
}

.question_info {
    padding: 24px;
    background: #1e1c2a;
    margin: 24px 0;
    border-radius: 8px;
}

.question_info h3 {
    padding-bottom: 12px;
}





.footer {
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 89%;
    margin: auto;


}

.footer a {
    margin: 10px;
}

.container p {
    font-size: 16px;
}

.support_footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 12vh;
    width: 100%;
    align-items: center;
    background-color: rgb(177, 131, 14);
    color: whitesmoke;
}