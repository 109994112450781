.iptv-container {
  display: flex;
  max-width: 800px;
  height: 500px;
  margin: auto;
  flex-direction: column;
  background-color: #f2f2f2;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  /* margin-top: 20px; */
}

.tabs {
  display: flex;
  background-color: #0a0101;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: 65px;
}

.tab {
  flex: 1;
  padding: 10px;
  cursor: pointer;
  color: goldenrod;
  text-align: center;
  transition: background-color 0.3s;
}

.tab.active {
  background-color: #ddd;
  font-weight: bold;
}

.content {
  flex: 1;
  padding: 20px;
}

h2 {
  text-align: center;
  color: #333;
}

/* Additional styling for each tab's content */
.content p {
  color: #555;
}

/* Media Query for Responsiveness */
@media screen and (max-width: 600px) {
  .tabs {
    flex-direction: column;
    align-items: stretch;
  }

  .tab {
    margin-bottom: 5px;
  }
}
button {
  background-color: #BB9F3E;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin-right: 10px; /* Add margin between buttons */
  margin-top: 2%;
}

button:hover {
  background-color: #45a049;
}

/* Styling for modal buttons */
.Modal__Button {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin-right: 10px; /* Add margin between buttons */
}

.Modal__Button:hover {
  background-color: #45a049;
}

/* Style for the table */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px; /* Adjust the top margin as needed */
}

/* Style for table headers */
th {
  background-color: #333; /* Black color for table headers */
  color: white;
  padding: 10px;
  text-align: left;
}

/* Style for table rows */
tr {
  border-bottom: 2px solid #333; /* Black color for table lines */
}

/* Style for table data cells */
td {
  padding: 10px;
}