ul {
    list-style-type: circle;
}

.news-content {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 100px 24px 24px 24px;
    align-items: center;
    justify-content: center;
    /* background-color: rgb(121, 120, 118); */


}

.news-content-card {
    background-color: #2d2b38;
    border-radius: 8px;
    border: 2px solid rgb(121, 120, 118);
    padding: 24px;
    width: 100%;
}

.news-content-card ul li {
    color: #fff;
}

.news-content-card a {
    display: block;
}

.news_content_card_header h1 {
    color: rgba(255, 255, 0, 0.644);
    font-size: 32px;
    text-align: center;
    padding-bottom: 24px;
}

.news_content_card_header p {
    padding-top: 24px;
}

.news-content-card h3 {
    color: #fff;
}

.news-content-card h4 {
    color: #fff;
}

.news-content p {
    font-size: 16px;
    color: #fff;
}

a {
    text-decoration: none;
    color: #3e8ef7;

}



.news-content .footer {
    width: 100%;
}

.news_footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 10px 12px;
    width: 100%;
    align-items: center;
    background-color: rgb(177, 131, 14);
    color: whitesmoke;
}