.home_header {
    height: 90vh;
    background-color: #1e1c2a;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding: 100px 24px 24px 24px;
}

.home_header h1 {

    font-style: normal;
    color: rgb(241, 235, 235);
    font-size: 48px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 20px;
    font-family: "Jost", sans-serif;

}

.home_header h2 {


    color: rgb(241, 235, 235);
    font-size: 24px;
    line-height: 38px;
    font-family: "Jost", sans-serif;
    margin-bottom: 45px;

}

.home_icon {
    max-width: 500px;
    height: 400px;
    width: 100%;
}

.home_icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    animation-name: infiniteupdown;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transition: all 0.2s;

}

@keyframes infiniteupdown {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

.header_logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

}

.img_wrapper {
    max-width: 100px;
    width: 100%;
    height: 48px;

}

.img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: #1e1c2a;
}

.img:hover {
    transform: translatey(-10px)
}

.tvplayer_description {
    background-color: #2d2b38;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.tvplayer_description ul li {
    list-style: none;
}

.tvplayer_description_about {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.tvplayer_description h1 {
    font-family: sans-serif;
    color: rgba(255, 255, 0, 0.644);
    text-align: center;
    font-size: 32px;
    font-weight: bold;

}

.tvplayer-desc p {
    margin-left: 25px;
    line-height: 25px;
    color: aliceblue;
}

.features_description {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;
    padding: 24px 32px;
    background-color: #2d2b38;
}

.features_description_about {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    align-items: center;
    justify-content: center;
    color: aliceblue;
}

.tvplayer-desc-right {
    color: aliceblue;
}


.features_header h1 {
    color: rgba(255, 255, 0, 0.644);
    font-family: sans-serif;
    padding: 20px;
    font-size: 32px;
    text-align: center;
}

.features_description p {
    text-align: center;
    color: #ffffff;
    padding: 20px;
}

.card {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    margin-bottom: 40px;

}
.card h2{
    color: gold;

}

.card:hover {
    transform: translatey(-10px);
    border-radius: 30px;

}

.card p {
    padding: 0px;
    text-align: left;
    font-size: 14px;
    line-height: 24px;
}

.card h2{
    font-size: 24px;
}

.underline {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 2px;
}

.whiteline {
    height: 2px;
    background-color: #ffffff;
    border-radius: 2px;
    width: 30px;
}

.blueline {
    height: 4px;
    background-color: blue;
    border-radius: 4px;
    width: 30px;
}

.home_footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 12vh;
    align-items: center;
    background-color: rgb(177, 131, 14);
    color: whitesmoke;
}

@media screen and (max-width: 868px) {
    .features_description_about {

        grid-template-columns: repeat(3, 1fr);


    }
}

@media screen and (max-width: 768px) {
    .home_header {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .tvplayer_description_about {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .features_description_about {

        grid-template-columns: repeat(2, 1fr);
        gap: 32px;

    }
}

@media screen and (max-width: 480px) {
    .features_description_about {

        grid-template-columns: repeat(1, 1fr);
        gap: 24px;

    }
}

/* @media screen and (max-width:370) {

    .home_header h1,
    p {
        font-size: 30px;
        font-weight: 300px;
    }


} */