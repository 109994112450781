.form_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    /* color: rgb(114, 30, 210); */
    width: 300px;
    height: 300px;
    box-shadow: 0 4px 8px 0 rgba(245, 238, 238, 0.2);
    margin-top: 70px;
    background-color: whitesmoke;
    border-radius: 20px;
    /* border: 2px solid ; */
}

input {

    font-size: 25px;
    margin: 20px;
}

label {
    font-weight: 500;
}

form h1 {
    margin: 0px 0 10px 0;
    color: #17cf97;
}


form .submit {
    background-color: rgb(114, 30, 210);
    color: whitesmoke;
    font-size: 20px;
    font-weight: 600;
    height: 36px;
    border-radius: 5px;
    width: 170px;
    border: 2px solid red;
    border: none;

}

.error_message{
    color: red;
}

.manageplaylist_loginform{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
}
@media screen and (max-width:780px){
form{
    width: 250px;
}
}

